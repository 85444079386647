import forms from "../../../../CSS/Components/Forms.module.css";
import sidebar from "../../../../CSS/Components/Sidebar.module.css";

const PresenterSymposiumView = () => {
  return (
    <div className={sidebar.box}>
      <h2 className={forms.h2}>Under development</h2>
    </div>
  );
};

export default PresenterSymposiumView;
